/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"

import { Grid, Typography } from "@mui/material"
import OptimizedImage from "../OptimizedImage"

const DrivenBrandsOffers = () => {
    return (
        <Grid container sx={{ justifyContent: "center", p: 0 }}>
            <Grid item xs={12}>
                {/* <a
                    href={"https://www.take5.com/car-wash/reduced-membership/"}
                    rel="noreferrer"
                    target="_blank"
                > */}
                <OptimizedImage
                    src={`/images/T5_Hero_Desktop.jpg`}
                    alt={`Exclusive offers on car maintenance`}
                    layout="fill"
                />
                {/* </a> */}
            </Grid>
            <Grid item xs={12} textAlign="center" sx={{ p: 3 }}>
                <Typography
                    component="h2"
                    variant="h1"
                    color="#000"
                    lineHeight={"1.9rem"}
                    fontWeight="fontWeightBold"
                    fontStyle="italic"
                    css={css`
                        @media (max-width: 768px) {
                            font-size: 1.4rem;
                            display: flex;
                            justify-content: center;
                        }
                    `}
                >
                    EXCLUSIVE OFFERS FROM OUR FAMILY OF BRANDS!
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <a
                    href={"https://www.autoglassnow.com/online-estimate/"}
                    rel="noreferrer"
                    target="_blank"
                >
                    <OptimizedImage
                        src={`/images/AGN Coupon - Maaco@2x.png`}
                        alt={`$50 off Full Replacement AGN coupon`}
                        layout="fill"
                    />
                </a>
            </Grid>
            <Grid item xs={4}>
                <a
                    href={
                        "https://www.take5.com/locations/?category=oil-change"
                    }
                    rel="noreferrer"
                    target="_blank"
                >
                    <OptimizedImage
                        src={`/images/T5OC Coupon2 - Maaco@2x.png`}
                        alt={`$50 off Full Replacement AGN coupon`}
                        layout="fill"
                    />
                </a>
            </Grid>
        </Grid>
    )
}

export default DrivenBrandsOffers
